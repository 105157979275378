import * as React from 'react';
import Button from '../components/button.js';
import Cards from '../components/cards.js';
import Layout from '../components/layout.js';
import {
  introWrapper,
  title,
  imgMobile,
  imgDesktop,
} from '../styles/index.module.css';

export default function IndexPage() {
  // const data = useStaticQuery(graphql`
  //   query GetBlogPosts {
  //     allMdx {
  //       nodes {
  //         id
  //         slug
  //         frontmatter {
  //           title
  //           description
  //           date(fromNow: true)
  //         }
  //       }
  //     }
  //   }
  // `);

  // const posts = data.allMdx.nodes;

  return (
    <Layout>
      <div className={introWrapper}>
        <div className={title}>
          <h1>Revista Cultural & Artística</h1>
          <img
            className={imgMobile}
            src="https://res.cloudinary.com/bethbecerra/image/upload/v1700640503/osdb8i65ouiopxp9labw.png"
            alt="Portada colibrí No. 8"
            width="auto"
            height="200"
          />
          <h2>
            A través de nuestros textos difundimos la gran diversidad de la
            escena artística local. Brindamos un espacio para la expresión y
            divulgación de la riqueza cultural mexicana.
          </h2>
          <Button />
        </div>
        <img
          className={imgDesktop}
          src="https://res.cloudinary.com/bethbecerra/image/upload/v1700640503/osdb8i65ouiopxp9labw.png"
          alt="Portada colibrí No. 8"
          width="auto"
          height="400"
        />
      </div>

      <Cards />

      {/* <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <Link to={post.slug}>{post.frontmatter.title}</Link>{' '}
            <small>posted {post.frontmatter.date}</small>
          </li>
        ))}
      </ul> */}
    </Layout>
  );
}

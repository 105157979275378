import * as React from 'react';
import colibri05 from '../../static/colibri05.pdf';
import colibri06 from '../../static/colibri-6-web.pdf';
import colibri07 from '../../static/colibri-7-web.pdf';
import colibri04 from '../../static/colibri-4-web.pdf';
import colibri03 from '../../static/colibri-3-web.pdf';
import colibri02 from '../../static/colibri-2-web.pdf';
import colibri01 from '../../static/colibri-1-web.pdf';
import colibri00 from '../../static/colibri-0-web.pdf';
import dossier01 from '../../static/colibri-dossier-1.pdf';
import { cardContainer, imgCard } from '../styles/index.module.css';

export default function Cards() {
  const numbers = [
    {
      link: colibri07,
      title: 'Colibrí #7',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1690147532/Magazine_Mockup_copy_ynqgfb.png',
    },
    {
      link: colibri06,
      title: 'Colibrí #6',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1656709294/coli-6-page_mhs93r.png',
    },
    {
      link: colibri05,
      title: 'Colibrí #5',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1647464733/inicio-presentacion-05_tpjn4w.png',
    },
    {
      link: colibri04,
      title: 'Colibrí #4',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1638225974/colibri-4-revista_w764u2.png',
    },
    {
      link: dossier01,
      title: 'Colibrí Dossier Vol. 1',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1651705888/lev1_iwg8jf.png',
    },
    {
      link: colibri03,
      title: 'Colibrí #3',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1701718990/colibri-3_fsjzyc.jpg',
    },
    {
      link: colibri02,
      title: 'Colibrí #2',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1701718988/Screenshot_2023-12-04_at_20.42.13_xng3t5.png',
    },
    {
      link: colibri01,
      title: 'Colibrí #1',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1701718988/Screenshot_2023-12-04_at_20.42.01_naikol.png',
    },
    {
      link: colibri00,
      title: 'Colibrí #0',
      image:
        'https://res.cloudinary.com/bethbecerra/image/upload/v1701718988/Screenshot_2023-12-04_at_20.41.46_vkcnbw.png',
    },
  ];

  console.log(numbers[1]);

  const listItems = numbers.map((number) => (
    <li key={number.title}>
      <a href={number.link}>
        <div className={cardContainer}>
          <img className={imgCard} src={number.image} alt="magazin-cover" />
          <h4>{number.title}</h4>
        </div>
      </a>
    </li>
  ));

  return <ul>{listItems}</ul>;
}
